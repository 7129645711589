<script>
import Loader from "../../components/xray/Loader";
import ApiService from "../../Utils/axios";
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: { Loader, VueHtml2pdf },
  data() {
    return {
      done: false,
      guide: null,
      pdfLoader: false,
      displayUpBtn: false,
      editable: { state: false, id: null },
    };
  },
  beforeMount() {
    this.fetchGuide();
  },
  mounted() {
    window.onscroll = () => {
      this.scrollFunction();
    };
  },

  methods: {
    fetchGuide() {
      new ApiService()
        .get("/api/guide/" + this.$route.params.id)
        .then((res) => {
          this.guide = res.data.data;
          document.title = res.data.data.title + " - Auditor";
          this.done = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    scrollTo(eleId) {
      const y =
        document.getElementById(eleId).getBoundingClientRect().top +
        window.scrollY -
        100;
      window.scroll({
        top: y,
        behavior: "smooth",
      });
    },

    generatePdf() {
      this.pdfLoader = true;
      this.$refs.html2Pdf.generatePdf();
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    scrollFunction() {
      if (
        document.body.scrollTop > 300 ||
        document.documentElement.scrollTop > 300
      ) {
        this.displayUpBtn = true;
      } else {
        this.displayUpBtn = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <b-container v-if="done" fluid>
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">Modifier un guide</h4>
        </template>

        <div
          class="iq-card-body"
          style="
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: 0 !important;
          "
        >
          <section>
            <!-- PDF Content Here -->
            <h3 class="text-primary p-4 text-center">
              {{ guide.title }}
            </h3>
            <div class="p-4">
              <h4 class="font-weight-bold" style="color: #ff7700">Sommaire</h4>
              <div v-for="chapter of guide.chapters" :key="chapter.id">
                <div
                  @click="scrollTo('cha' + chapter.id)"
                  class="summary-click d-flex justify-content-between mt-3"
                >
                  <h5 class="ml-3 text-primary">
                    {{ chapter.reference + " - " + chapter.title }}
                  </h5>
                  <h5 class="text-white">
                    Aller vers <i class="fas fa-arrow-down"></i>
                  </h5>
                </div>
                <div
                  v-for="section of chapter.sections"
                  class="ml-5"
                  :key="section.id"
                >
                  <div
                    @click="scrollTo('sec' + section.id)"
                    class="mt-2 summary-click d-flex justify-content-between"
                  >
                    <h6>
                      {{ section.refernce + " - " + section.title }}
                    </h6>
                    <h5 class="text-white">
                      Aller vers <i class="fas fa-arrow-down"></i>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-4">
              <h4 class="font-weight-bold mb-2" style="color: #ff7700">
                Description
              </h4>
              <p class="text-black ml-5 mr-5 text-justify">
                {{ guide.summary }}
              </p>
            </div>
            <hr />
            <div v-for="chapter of guide.chapters" :key="chapter.id">
              <div class="p-4">
                <div class="d-flex justify-content-between p-2 hover">
                  <h4
                    v-if="!editable.state || editable.id != 'cha' + chapter.id"
                    :id="'cha' + chapter.id"
                    class="font-weight-bold"
                    style="color: #ff7700"
                  >
                    {{ chapter.reference + " - " + chapter.title }}
                  </h4>
                  <b-input
                    :ref="chapter.id"
                    class="col-10"
                    v-else-if="
                      editable.state && editable.id == 'cha' + chapter.id
                    "
                    :value="chapter.title"
                  />
                  <div class="d-flex" v-if="!editable.state">
                    <b-button
                      variant=" iq-bg-success mr-1 mt-1"
                      size="sm"
                      @click="
                        editable.state = true;
                        editable.id = 'cha' + chapter.id;
                        $refs[chapter.id].focus();
                      "
                      ><i class="ri-ball-pen-fill m-0"></i
                    ></b-button>
                    <b-button variant=" iq-bg-danger mr-1 mt-1" size="sm"
                      ><i class="ri-delete-bin-line m-0"></i
                    ></b-button>
                  </div>
                  <div
                    v-else-if="
                      editable.state && editable.id == 'cha' + chapter.id
                    "
                    class="d-flex"
                  >
                    <b-button
                      variant=" iq-bg-success mr-1 mt-1"
                      size="sm"
                      @click="
                        editable.state = false;
                        editable.id = 'cha' + chapter.id;
                      "
                      ><i class="fas fa-check m-0"></i
                    ></b-button>
                    <b-button variant=" iq-bg-danger mr-1 mt-1" size="sm"
                      ><i class="fas fa-times m-0"></i
                    ></b-button>
                  </div>
                </div>
                <div
                  v-for="section of chapter.sections"
                  class="section"
                  :key="section.id"
                >
                  <div class="d-flex justify-content-between p-2 hover">
                    <h5
                      v-if="
                        !editable.state || editable.id != 'sec' + section.id
                      "
                      :id="'sec' + section.id"
                      class="p-2 text-primary"
                    >
                      {{ section.refernce + " " + section.title }}
                    </h5>
                    <b-input
                      :ref="section.id"
                      class="col-10"
                      v-else-if="
                        editable.state && editable.id == 'sec' + section.id
                      "
                      :value="section.title"
                    />
                    <div
                      class="d-flex"
                      style="max-height: 37px"
                      v-if="!editable.state"
                    >
                      <b-button
                        variant=" iq-bg-success mr-1 mt-1"
                        size="sm"
                        @click="
                          editable.state = true;
                          editable.id = 'sec' + section.id;
                          $refs[section.id].focus();
                        "
                        ><i class="ri-ball-pen-fill m-0"></i
                      ></b-button>
                      <b-button variant=" iq-bg-danger mr-1 mt-1" size="sm"
                        ><i class="ri-delete-bin-line m-0"></i
                      ></b-button>
                    </div>
                    <div
                      v-else-if="
                        editable.state && editable.id == 'sec' + section.id
                      "
                      class="d-flex"
                      style="max-height: 37px"
                    >
                      <b-button
                        variant=" iq-bg-success mr-1 mt-1"
                        size="sm"
                        @click="
                          editable.state = false;
                          editable.id = 'sec' + section.id;
                        "
                        ><i class="fas fa-check m-0"></i
                      ></b-button>
                      <b-button variant=" iq-bg-danger mr-1 mt-1" size="sm"
                        ><i class="fas fa-times m-0"></i
                      ></b-button>
                    </div>
                  </div>
                  <div class="card">
                    <section
                      class="p-3 crit pdf-item"
                      v-for="(crit, i) of section.criteria"
                      :key="crit.id"
                    >
                      <hr v-if="i >= 1" />
                      <div class="d-flex" v-if="!crit.subcriteria.length">
                        <div class="col-lg-9" style="font-size: medium">
                          <p
                            v-if="
                              !editable.state || editable.id != 'crit' + crit.id
                            "
                            class="text-black"
                          >
                            {{ crit.refernce }} - {{ crit.question }}
                          </p>
                          <b-input
                            :ref="crit.id"
                            class="col-10"
                            v-else-if="
                              editable.state && editable.id == 'crit' + crit.id
                            "
                            :value="crit.question"
                          />
                          <div
                            style="
                              border: 2px solid #ff7700;
                              border-radius: 10px;
                            "
                            class="p-3"
                            v-if="crit.requirements"
                          >
                            <span
                              class="font-weight-bold"
                              style="color: #ff7700"
                              >{{ $t("buttons.requirements") }}</span
                            >
                            <nl2br
                              tag="p"
                              :text="crit.requirements"
                              class-name="pt-2 pl-3"
                            />
                          </div>
                          <div
                            v-if="crit.explanations"
                            style="
                              border: 2px solid #2f48c5;
                              border-radius: 10px;
                            "
                            class="p-3 mt-3"
                          >
                            <span class="text-primary font-weight-bold">{{
                              $t("buttons.explanations")
                            }}</span>
                            <nl2br
                              tag="p"
                              :text="crit.explanations"
                              class-name="pt-2 pl-3"
                            />
                          </div>
                        </div>
                        <div class="col-lg-2 text-center criteria-options">
                          <div v-if="crit.critical" class="text-danger">
                            <i class="fas fa-exclamation-circle" /> Critique
                          </div>
                          <div class="text-center mt-3">
                            <span v-if="crit.level == 4">
                              <i class="fas fa-star star-gold star" />
                              <i class="fas fa-star star-gold star" />
                              <i class="fas fa-star star-gold star" />

                              <i class="fas fa-crown star-gold star" />
                            </span>
                            <span v-if="crit.level == 3">
                              <i class="fas fa-star star-gold star" />
                              <i class="fas fa-star star-gold star" />
                              <i class="fas fa-star star-gold star" />

                              <i class="fas fa-crown star" />
                            </span>
                            <span v-if="crit.level == 2">
                              <i class="fas fa-star star-gold star" />
                              <i class="fas fa-star star-gold star" />
                              <i class="fas fa-star star" />

                              <i class="fas fa-crown star" />
                            </span>
                            <span v-if="crit.level == 1">
                              <i class="fas fa-star star-gold star" />
                              <i class="fas fa-star star" />
                              <i class="fas fa-star star" />

                              <i class="fas fa-crown star" />
                            </span>
                          </div>
                        </div>
                        <div
                          class="d-flex justify-content-end col-lg-1"
                          v-if="!editable.state"
                          style="max-height: 37px"
                        >
                          <b-button
                            variant=" iq-bg-success mr-1 mt-1"
                            size="sm"
                            @click="
                              editable.state = true;
                              editable.id = 'crit' + crit.id;
                              $refs[crit.id].focus();
                            "
                            ><i class="ri-ball-pen-fill m-0"></i
                          ></b-button>
                          <b-button variant=" iq-bg-danger mr-1 mt-1" size="sm"
                            ><i class="ri-delete-bin-line m-0"></i
                          ></b-button>
                        </div>
                        <div
                          v-else-if="
                            editable.state && editable.id == 'crit' + crit.id
                          "
                          class="d-flex justify-content-end col-lg-1"
                          style="max-height: 37px"
                        >
                          <b-button
                            variant=" iq-bg-success mr-1 mt-1"
                            size="sm"
                            @click="
                              editable.state = false;
                              editable.id = 'crit' + crit.id;
                            "
                            ><i class="fas fa-check m-0"></i
                          ></b-button>
                          <b-button variant=" iq-bg-danger mr-1 mt-1" size="sm"
                            ><i class="fas fa-times m-0"></i
                          ></b-button>
                        </div>
                      </div>
                      <div v-else style="margin-left: 20px">
                        <div style="font-size: medium">
                          <div class="d-flex justify-content-between">
                            <p
                              class="text-black col-9"
                              v-if="
                                !editable.state ||
                                editable.id != 'crit' + crit.id
                              "
                            >
                              {{ crit.refernce }} - {{ crit.question }}
                            </p>

                            <b-input
                              :ref="crit.id"
                              class="col-9"
                              v-else-if="
                                editable.state &&
                                editable.id == 'crit' + crit.id
                              "
                              :value="crit.question"
                            />
                            <div class="text-center mt-3">
                              <span v-if="crit.level == 4">
                                <i class="fas fa-star star-gold star" />
                                <i class="fas fa-star star-gold star" />
                                <i class="fas fa-star star-gold star" />

                                <i class="fas fa-crown star-gold star" />
                              </span>
                              <span v-if="crit.level == 3">
                                <i class="fas fa-star star-gold star" />
                                <i class="fas fa-star star-gold star" />
                                <i class="fas fa-star star-gold star" />

                                <i class="fas fa-crown star" />
                              </span>
                              <span v-if="crit.level == 2">
                                <i class="fas fa-star star-gold star" />
                                <i class="fas fa-star star-gold star" />
                                <i class="fas fa-star star" />

                                <i class="fas fa-crown star" />
                              </span>
                              <span v-if="crit.level == 1">
                                <i class="fas fa-star star-gold star" />
                                <i class="fas fa-star star" />
                                <i class="fas fa-star star" />

                                <i class="fas fa-crown star" />
                              </span>
                            </div>
                            <div
                              class="d-flex justify-content-end"
                              v-if="!editable.state"
                              style="max-height: 37px"
                            >
                              <b-button
                                variant=" iq-bg-success mr-1 mt-1"
                                size="sm"
                                @click="
                                  editable.state = true;
                                  editable.id = 'crit' + crit.id;
                                  $refs[crit.id].focus();
                                "
                                ><i class="ri-ball-pen-fill m-0"></i
                              ></b-button>
                              <b-button
                                variant=" iq-bg-danger mr-1 mt-1"
                                size="sm"
                                ><i class="ri-delete-bin-line m-0"></i
                              ></b-button>
                            </div>
                            <div
                              v-else-if="
                                editable.state &&
                                editable.id == 'crit' + crit.id
                              "
                              class="d-flex justify-content-end"
                              style="max-height: 37px"
                            >
                              <b-button
                                variant=" iq-bg-success mr-1 mt-1"
                                size="sm"
                                @click="
                                  editable.state = false;
                                  editable.id = 'crit' + crit.id;
                                "
                                ><i class="fas fa-check m-0"></i
                              ></b-button>
                              <b-button
                                variant=" iq-bg-danger mr-1 mt-1"
                                size="sm"
                                ><i class="fas fa-times m-0"></i
                              ></b-button>
                            </div>
                          </div>
                          <div
                            v-for="subcrit of crit.subcriteria"
                            :key="subcrit.id"
                            class="d-flex sub-crit"
                          >
                            <div class="col-lg-9">
                              <p
                                v-if="
                                  !editable.state ||
                                  editable.id != 'subcrit' + subcrit.id
                                "
                                class="text-black"
                              >
                                {{ subcrit.refernce }} -
                                {{ subcrit.question }}
                              </p>
                              <b-input
                                :ref="subcrit.id"
                                class="col-10"
                                v-else-if="
                                  editable.state &&
                                  editable.id == 'subcrit' + subcrit.id
                                "
                                :value="subcrit.question"
                              />
                              <div
                                style="
                                  border: 2px solid #ff7700;
                                  border-radius: 10px;
                                "
                                class="p-3"
                                v-if="subcrit.requirements"
                              >
                                <span
                                  class="font-weight-bold"
                                  style="color: #ff7700"
                                  >{{ $t("buttons.requirements") }}</span
                                >
                                <nl2br
                                  tag="p"
                                  :text="subcrit.requirements"
                                  class-name="pt-2 pl-3"
                                />
                              </div>
                              <div
                                v-if="subcrit.explanations"
                                style="
                                  border: 2px solid #2f48c5;
                                  border-radius: 10px;
                                "
                                class="p-3 mt-3"
                              >
                                <span class="text-primary font-weight-bold">{{
                                  $t("buttons.explanations")
                                }}</span>
                                <nl2br
                                  tag="p"
                                  :text="subcrit.explanations"
                                  class-name="pt-2 pl-3"
                                />
                              </div>
                            </div>
                            <div class="col-lg-2 text-center criteria-options">
                              <div v-if="subcrit.critical" class="text-danger">
                                <i class="fas fa-exclamation-circle" />
                                Critique
                              </div>
                            </div>
                            <div
                              class="d-flex justify-content-end col-lg-1"
                              v-if="!editable.state"
                              style="max-height: 37px"
                            >
                              <b-button
                                variant=" iq-bg-success mr-1 mt-1"
                                size="sm"
                                @click="
                                  editable.state = true;
                                  editable.id = 'subcrit' + subcrit.id;
                                  $refs[subcrit.id].focus();
                                "
                                ><i class="ri-ball-pen-fill m-0"></i
                              ></b-button>
                              <b-button
                                variant=" iq-bg-danger mr-1 mt-1"
                                size="sm"
                                ><i class="ri-delete-bin-line m-0"></i
                              ></b-button>
                            </div>
                            <div
                              v-else-if="
                                editable.state &&
                                editable.id == 'subcrit' + subcrit.id
                              "
                              class="d-flex justify-content-end col-lg-1"
                              style="max-height: 37px"
                            >
                              <b-button
                                variant=" iq-bg-success mr-1 mt-1"
                                size="sm"
                                @click="
                                  editable.state = false;
                                  editable.id = 'subcrit' + subcrit.id;
                                "
                                ><i class="fas fa-check m-0"></i
                              ></b-button>
                              <b-button
                                variant=" iq-bg-danger mr-1 mt-1"
                                size="sm"
                                ><i class="fas fa-times m-0"></i
                              ></b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <section
                  class="p-3 crit-outside pdf-item"
                  v-for="(crit, i) of chapter.criteria"
                  :key="crit.id"
                >
                  <hr v-if="i >= 1" />
                  <div class="d-flex" v-if="!crit.subcriteria.length">
                    <div class="col-lg-9" style="font-size: medium">
                      <p
                        class="text-black"
                        v-if="
                          !editable.state || editable.id != 'crit' + crit.id
                        "
                      >
                        {{ crit.refernce }} - {{ crit.question }}
                      </p>
                      <b-input
                        :ref="crit.id"
                        v-else-if="
                          editable.state && editable.id == 'crit' + crit.id
                        "
                        :value="crit.question"
                      />
                      <div
                        style="border: 2px solid #ff7700; border-radius: 10px"
                        class="p-3"
                        v-if="crit.requirements"
                      >
                        <span class="font-weight-bold" style="color: #ff7700">{{
                          $t("buttons.requirements")
                        }}</span>
                        <nl2br
                          class-name="pt-2 pl-3"
                          tag="p"
                          :text="crit.requirements"
                        />
                      </div>
                      <div
                        v-if="crit.explanations"
                        style="border: 2px solid #2f48c5; border-radius: 10px"
                        class="p-3 mt-3"
                      >
                        <span class="text-primary font-weight-bold">{{
                          $t("buttons.explanations")
                        }}</span>
                        <p class="pt-2 pl-3">
                          {{ crit.explanations }}
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-2 text-center criteria-options">
                      <div v-if="crit.critical" class="text-danger">
                        <i class="fas fa-exclamation-circle" /> Critique
                      </div>
                      <div class="text-center mt-3">
                        <span v-if="crit.level == 4">
                          <i class="fas fa-star star-gold star" />
                          <i class="fas fa-star star-gold star" />
                          <i class="fas fa-star star-gold star" />

                          <i class="fas fa-crown star-gold star" />
                        </span>
                        <span v-if="crit.level == 3">
                          <i class="fas fa-star star-gold star" />
                          <i class="fas fa-star star-gold star" />
                          <i class="fas fa-star star-gold star" />

                          <i class="fas fa-crown star" />
                        </span>
                        <span v-if="crit.level == 2">
                          <i class="fas fa-star star-gold star" />
                          <i class="fas fa-star star-gold star" />
                          <i class="fas fa-star star" />

                          <i class="fas fa-crown star" />
                        </span>
                        <span v-if="crit.level == 1">
                          <i class="fas fa-star star-gold star" />
                          <i class="fas fa-star star" />
                          <i class="fas fa-star star" />

                          <i class="fas fa-crown star" />
                        </span>
                      </div>
                    </div>
                    <div
                      class="d-flex justify-content-end col-lg-1"
                      v-if="!editable.state"
                      style="max-height: 37px"
                    >
                      <b-button
                        variant=" iq-bg-success mr-1 mt-1"
                        size="sm"
                        @click="
                          editable.state = true;
                          editable.id = 'crit' + crit.id;
                          $refs[crit.id].focus();
                        "
                        ><i class="ri-ball-pen-fill m-0"></i
                      ></b-button>
                      <b-button variant=" iq-bg-danger mr-1 mt-1" size="sm"
                        ><i class="ri-delete-bin-line m-0"></i
                      ></b-button>
                    </div>
                    <div
                      v-else-if="
                        editable.state && editable.id == 'crit' + crit.id
                      "
                      class="d-flex justify-content-end col-lg-1"
                      style="max-height: 37px"
                    >
                      <b-button
                        variant=" iq-bg-success mr-1 mt-1"
                        size="sm"
                        @click="
                          editable.state = false;
                          editable.id = 'crit' + crit.id;
                        "
                        ><i class="fas fa-check m-0"></i
                      ></b-button>
                      <b-button variant=" iq-bg-danger mr-1 mt-1" size="sm"
                        ><i class="fas fa-times m-0"></i
                      ></b-button>
                    </div>
                  </div>
                  <div v-else style="margin-left: 20px">
                    <div style="font-size: medium">
                      <p class="text-black">
                        {{ crit.refernce }} - {{ crit.question }}
                      </p>
                      <div
                        v-for="subcrit of crit.subcriteria"
                        :key="subcrit.id"
                        class="d-flex sub-crit"
                      >
                        <div class="col-lg-10">
                          <p class="text-black">
                            {{ subcrit.refernce }} - {{ subcrit.question }}
                          </p>
                          <div
                            style="
                              border: 2px solid #ff7700;
                              border-radius: 10px;
                            "
                            class="p-3"
                            v-if="subcrit.requirements"
                          >
                            <span
                              class="font-weight-bold"
                              style="color: #ff7700"
                              >{{ $t("buttons.requirements") }}</span
                            >
                            <nl2br
                              tag="p"
                              :text="subcrit.requirements"
                              class-name="pt-2 pl-3"
                            />
                          </div>
                          <div
                            v-if="subcrit.explanations"
                            style="
                              border: 2px solid #2f48c5;
                              border-radius: 10px;
                            "
                            class="p-3 mt-3"
                          >
                            <span class="text-primary font-weight-bold">{{
                              $t("buttons.explanations")
                            }}</span>
                            <p class="pt-2 pl-3">
                              {{ subcrit.explanations }}
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-2 text-center criteria-options">
                          <div v-if="subcrit.critical" class="text-danger">
                            <i class="fas fa-exclamation-circle" /> Critique
                          </div>
                          <div class="text-center mt-3">
                            <span v-if="subcrit.level == 4">
                              <i class="fas fa-star star-gold star" />
                              <i class="fas fa-star star-gold star" />
                              <i class="fas fa-star star-gold star" />

                              <i class="fas fa-crown star-gold star" />
                            </span>
                            <span v-if="subcrit.level == 3">
                              <i class="fas fa-star star-gold star" />
                              <i class="fas fa-star star-gold star" />
                              <i class="fas fa-star star-gold star" />

                              <i class="fas fa-crown star" />
                            </span>
                            <span v-if="subcrit.level == 2">
                              <i class="fas fa-star star-gold star" />
                              <i class="fas fa-star star-gold star" />
                              <i class="fas fa-star star" />

                              <i class="fas fa-crown star" />
                            </span>
                            <span v-if="subcrit.level == 1">
                              <i class="fas fa-star star-gold star" />
                              <i class="fas fa-star star" />
                              <i class="fas fa-star star" />

                              <i class="fas fa-crown star" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <!-- <div class="html2pdf__page-break" /> -->
            </div>
          </section>
        </div>
      </iq-card>
      <b-button v-if="displayUpBtn" id="btn-back-to-top" @click="scrollTop">
        <i style="margin-right: 0" class="fas fa-arrow-up"></i>
      </b-button>
    </b-container>
    <Loader v-if="!done" />
  </div>
</template>

<style>
#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 35px;
  z-index: 99;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: x-large;
  background: #ff7700;
  width: 60px;
  height: 60px;
  border-radius: 60px;
}
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}
.section {
  border-left: 2px solid rgb(187, 187, 187);
  margin-left: 10px;
}
.crit {
  border-left: 2px solid rgb(187, 187, 187);
  margin-left: 30px;
  min-height: 130px;
}
.star {
  margin-left: 7px;
}

.star-gold {
  color: gold;
}

.crit-outside {
  border-left: 2px solid rgb(187, 187, 187);

  margin-left: 10px;
  min-height: 130px;
}

.summary-click:hover {
  background-color: #2f48c5 !important;
  border-radius: 5px;

  padding-top: 10px;
  padding-bottom: 10px;
}

.summary-click:hover h6,
.summary-click:hover h5 {
  color: white !important;
}

.summary-click {
  cursor: pointer;
  transition-duration: 300ms;
  padding-left: 10px;
  padding-right: 10px;
}
.hover:hover {
  background-color: rgb(236, 236, 236);
  border-radius: 5px;
}
</style>
